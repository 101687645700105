import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/surviving-unprecedented-traffic-spikes",
  "date": "7th February 2023",
  "title": "Surviving unprecedented traffic spikes: a case study",
  "summary": "Three ways to safeguard site performance during massive increases in web traffic.",
  "author": "Kim Pepper",
  "tag": "Case Study",
  "tagColor": "green",
  "tags": [{
    "name": "presentation"
  }, {
    "name": "scaling"
  }, {
    "name": "case study"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The context: an unprecedented pandemic`}</h2>
    <p>{`In Australia, the pandemic resulted in our government agency clients becoming the go-to source of critical, daily information. `}</p>
    <p>{`In a short time, this activity led to a massive increase in web traffic, equating to approximately 360 million daily requests. Meanwhile, error rates remained significantly low, at less than 0.01%.`}</p>
    <p>{`So, how do you ensure your websites continue to perform during these high-traffic events in a pandemic or during other crises?`}</p>
    <p>{`Through our hosting platform, Skpr, and its underlying infrastructure of AWS, we discovered `}<a parentName="p" {...{
        "href": "https://www.skpr.com.au/blog/scaling-during-covid19-pandemic"
      }}>{`three key learnings`}</a>{` that you can apply to safeguard your environment through prolonged traffic spikes.`}</p>
    <h3>{`- A solid caching strategy.`}</h3>
    <ul>
      <li parentName="ul">{`Maintain an efficient cache with a hit ratio above 75% (sites on our platform hit 99% during the pandemic).`}</li>
      <li parentName="ul">{`Serve cached items for longer and reuse items.`}</li>
      <li parentName="ul">{`Use CloudFront to protect the user experience.`}</li>
      <li parentName="ul">{`Reduce cache key variation.`}</li>
      <li parentName="ul">{`Undertake automatic testing for critical pages using CI tools.`}</li>
      <li parentName="ul">{`Use an external In-Memory cache to avoid database deadlocks.`}</li>
    </ul>
    <h3>{`- Scaling out on demand.`}</h3>
    <ul>
      <li parentName="ul">{`Manage a Kubernetes cluster and other AWS-managed services.`}</li>
      <li parentName="ul">{`Undertake horizontal pod autoscaling using Kubernetes.`}</li>
      <li parentName="ul">{`Cluster autoscale using CloudFront, Elastic Load Balancer and Elastic File System.`}</li>
      <li parentName="ul">{`Decouple the state of the application.`}</li>
      <li parentName="ul">{`Fine-tune PHP resources to manage the maximum number of processors.`}</li>
    </ul>
    <h3>{`- A protected database.`}</h3>
    <ul>
      <li parentName="ul">{`Use Amazon Aurora for MySQL for smoother, faster scaling or infrastructure change.`}</li>
      <li parentName="ul">{`Use AWS RDS Proxy to make faster new connections.`}</li>
      <li parentName="ul">{`Use K6 to measure load testing.`}</li>
      <li parentName="ul">{`Use New Relic to fix bottlenecks.`}</li>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "https://www.skpr.com.au/blog/building-cloudwatch-dashboards"
        }}>{`CloudWatch Dashboards`}</a>{` to monitor impacts on infrastructure.`}</li>
    </ul>
    <p>{`The following video provides further detailed technical insights into how Skpr provisions and manages this infrastructure to help our clients respond to unprecedented traffic spikes.`}</p>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="yXEil10HLyM" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '979'
      }
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      